import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Button from '../button';
import Container from '../container';
import Home from '../../pages/home';
import TermsOfAgreement from '../../pages/terms-of-agreement';
import c from './style.module.scss';

const AppLayout = ({ children }) => {
  return (
    <Router>
      <div className={c.layout}>
        <Container>
          <header>
            <Link to="/">
              <img src="/img/app-logo.png" alt="app logo" />
            </Link>
            <Button href="https://t.me/CarFaxDoc_bot">Придбати CarFax</Button>
          </header>
        </Container>
        <Container>
          <main>
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms-of-agreement" element={<TermsOfAgreement />} />
        </Routes>
          </main>
        </Container>
        <Container color="#F5F7FA">
          <footer>
            <div className={c.left}>
              <Link to="/">
              <img
                width={40}
                height={41}
                src="/img/app-logo.png"
                alt="app logo"
              />
              </Link>
              <span>Copyright © {new Date().getFullYear()} CarFaxDoc</span>
            </div>
            <div className={c.right}>
              <a rel="noreferrer noopener" target='_blank' href="https://t.me/CarFaxDoc_bot">Telegram Bot</a>
              <Link to="/terms-of-agreement">
                <span>Договір оферти</span>
              </Link>
            </div>
          </footer>
        </Container>
      </div>
    </Router>
  );
}

export default AppLayout;