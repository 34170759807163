import React from 'react';
import c from './style.module.scss'

const Button = ({as = 'a', children, ...rest}) => {
  const Component = as;
  return (
    <Component className={c.button} {...rest}>
      {children}
    </Component>
  );
};

export default Button;