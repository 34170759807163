import React from 'react';
import { termsData } from './termsData';
import c from './style.module.scss';

const TermsOfAgreement = () => {
  return (
    <div className={c.terms}>
      <h2>ДОГОВІР ОФЕРТИ</h2>
      <div className={c.container}>
        {termsData.map(({title, items}, idx) => {
          return (
            <div className={c.group}>
              <p className={c.title}>{idx+1}.{title}</p>
              {items.map((desc, descIdx)=> {
                return (
                  <p className={c.desc}>
                    {idx+1}.{descIdx + 1}
                    {` `}
                    <span dangerouslySetInnerHTML={{__html: desc}} />
                  </p>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TermsOfAgreement;