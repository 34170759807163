import React from 'react';
import c from './style.module.scss';

const packagesData = [
  {
    type: 'Стандарт',
    numberReports: '1 звіт',
    price: '$2.99',
    perItemNewPrice: '$2.99/шт'
  },
  {
    type: 'Середній',
    numberReports: '3 звіти',
    price: '$7',
    perItemNewPrice: '$2.33/шт',
    perItemOldPrice: '$2.99/шт'
  },
  {
    type: "Преміум",
    numberReports: '10 звітів',
    price: '$20',
    perItemNewPrice: '$2/шт',
    perItemOldPrice: '$2.99/шт'
  }
]

const Packages = () => {
  return (
    <div className={c.packages}>
      <p className={c.descritpion}>
        Придбавши потрібний пакет, Ви зможете в любий момент отримати потрібний
        CarFax звіт за вказаним VIN номером. Кошти будуть збережені в вашому
        особистому кабінеті, які Ви зможете використовувати тоді коли це Вам
        потрібно
      </p>
      <div className={c.itemList}>
        {packagesData.map(
          ({
            type,
            numberReports,
            price,
            perItemNewPrice,
            perItemOldPrice,
          }) => {
            return (
              <div className={c.package}>
                <p className={c.type}>
                  {type}
                  <br />
                  <span>{numberReports}</span>
                </p>
                <p className={c.price}>{price}</p>
                <p className={c.itemPrice}>
                  {perItemOldPrice && <span className={c.oldPrice}>{perItemOldPrice}</span>}
                  <span>{perItemNewPrice}</span>
                </p>
              </div>
            )
          }
        )}
      </div>
    </div>
  );
}

export default Packages;