import React from 'react';
import Button from '../../button';
import c from './style.module.scss';

const Hero = () => {
  return (
    <div className={c.hero}>
      <div className={c.left}>
        <h1>
          {`Звіт `}
          <span>CarFax</span>
          {` прямо у Вашому телефоні`}
        </h1>
        <p>Відкрийте для себе швидкий та зручний спосіб купівлі CarFax</p>
        <Button href="https://t.me/CarFaxDoc_bot" rel="noopener noreferrer" target="_blank">ПЕРЕЙТИ В TELEGRAM BOT</Button>
      </div>
      <img src="/img/hero.png" alt="Hero section" />
    </div>
  )
}

export default Hero;