import React from 'react'

import AppLayout from './components/app-layout';


function App() {
  return (
    <AppLayout/>
  );
}

export default App;
