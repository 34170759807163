import React from 'react';
import c from './style.module.scss';

const Container = ({ children, color = '#fff' }) => {
  return (
    <div className={c.container} style={{ '--container-color': color }}>
      <div className={c.inner}>
        {children}
      </div>
    </div>
  )
}

export default Container;