import React from 'react';
import Checkmark from '../../svg/checkmark';
import c from './style.module.scss';

const infoItems = [
  'Чи був автомобіль викрадений?',
  'Чи відбувалися аварії з участю цього автомобіля?',
  'Скільки у автомобіля було власників?',
  'Історія пошкоджень автомобіля',
  'Чи потребував автомобіль ремонту або відновлення після аварії?'
];

const Info = () => { 
  return (
    <div className={c.info}>
      <div className={c.left}>
        <p className={c.title}>Яку інформацію надає CarFax звіт</p>
        <p className={c.subtitle}>
          За допомогою CarFax звіту Ви отримаєте інформацію про усі дії які
          відбувались з автомобілем коли він був в Штатах, Канаді
        </p>
      </div>
      <div className={c.right}>
        {infoItems.map((item, index) => (
          <div key={item} className={c.item}>
            <Checkmark />
            <p>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Info;