import React from 'react';
import Hero from '../../components/sections/hero';
import Packages from '../../components/sections/packages';
import Info from '../../components/sections/info';

const Home = () => {
  return (
    <>
      <Hero />
      <Packages />
      <Info />
    </>
  )
}

export default Home;