export const termsData = [
  {
    title: 'ВИЗНАЧЕННЯ ТЕРМІНІВ',
    items: [
    'Публічна оферта (далі – «Оферта») - публічна пропозиція Продавця, адресована невизначеному колу осіб, укласти з Продавцем договір купівлі-продажу товару дистанційним способом (далі – «Договір») на умовах, що містяться в цій Оферті, включаючи всі Додатки.',
    'Замовлення Товару через телеграм бот – позиції, вказані Покупцем з асортименту Товару, запропонованого до продажу, при оформленні заявки на придбання Товару через телеграм бот.'
    ]
  },
  {
    title: 'ЗАГАЛЬНІ ПОЛОЖЕННЯ',
    items: [
      'Замовлення Покупцем Товару, розміщеного через телеграм бот означає, що Покупець погоджується з усіма умовами цієї Оферти.',
      'Адміністрація телеграм бота має право вносити зміни до Оферти без повідомлення Покупця.',
      'Термін дії Оферти не обмежений, якщо інше не зазначено в розділі “Інформація” телеграм бота.',
      'Продавець надає Покупцю повну та достовірну інформацію про Товар, включаючи інформацію про основні споживчі властивості Товару.'
    ]
  },
  {
    title: 'ЦІНА ТОВАРУ',
    items: [
      'Ціна на кожну позицію Товару вказана в розділі "Інформація", пункт "Ціни, та як придбати CarFax"  телеграм бота.',
      'Продавець має право в односторонньому порядку змінити ціну на будь-яку позицію товару.',
      `У разі зміни ціни на замовлений Товар Продавець зобов'язується протягом кількох днів проінформувати Покупця про зміну ціни Товару.`,
      'Покупець має право підтвердити або анулювати Замовлення на придбання Товару, якщо ціна змінена Продавцем після оформлення Замовлення.',
      'Зміна Продавцем ціни на сплачений Покупцем Товар не допускається.',
      'Продавець вказує вартість Товару через телеграм бот.',
      `Зобов'язання Покупця з оплати Товару вважаються виконаними з моменту надходження Продавцем коштів.`
    ]
  },
  {
    title: 'ОФОРМЛЕННЯ ЗАМОВЛЕННЯ',
    items: [
      'Замовлення Товару здійснюється Покупцем через телеграм бот <a rel="noopener noreferrer" target="_blank" href="https://t.me/CarFaxDoc_bot">https://t.me/CarFaxDoc_bot<a>',
      'Найменування, кількість, асортимент, ціна обраного Покупцем Товару зазначаються у листуванні Покупця через телеграм бот.',
      'Продавець не несе відповідальності за зміст та достовірність інформації, наданої Покупцем під час оформлення Замовлення.'
    ]
  },
  {
    title: 'ДОСТАВКА ТА ПЕРЕДАЧА ТОВАРУ ПОКУПЦЮ',
    items: [
      'Після успішної оплати звіт буде відображено у листуванні Telegram, та збережено у його особистому кабінеті для подальшого доступу.'
    ]
  },
  {
    title: 'УМОВИ I ПРАВИЛА ПОВЕРНЕННЯ',
    items: [
      'Ми гарантуємо 100% якість продуктів і послуг, надаючи прості правила повернення. У разі виникнення помилки при створенні звіту, ми повернемо вам 100% коштів. Якщо у вас виникнуть питання стосовно вашого замовлення або політики повернення, не соромтеся відправляти всю інформацію технічній підтримці <a target="_blank" rel="noopener noreferrer" href="https://t.me/Carfax_support">https://t.me/Carfax_support.</a>'
    ]
  }
]